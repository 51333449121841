import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { PrimaryHeading } from '../components/reusables/textElements';
import { breakpoints } from '../lib/constants';
import AppChrome from '../components/AppChrome';
import ArticlePreview from '../components/latest/ArticlePreview';
import ContentWrapper from '../components/reusables/ContentWrapper';
import TopNav from '../components/TopNav';

const Grid = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  columnGap: 20,
  rowGap: 20,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    gridTemplateColumns: '1fr',
  },
});

function CategoryTemplate({ data }) {
  const { title, slug } = data.contentfulCategory;

  const articles = data.allContentfulArticle.edges
    .filter(({ node }) => node.category.slug === slug)
    .map(({ node }) => node);

  return (
    <AppChrome
      title={title}
      og={{
        image: articles[0] && articles[0].image.sizes.src,
        title,
        description: articles[0] && articles[0].description,
      }}
    >
      <TopNav />
      <ContentWrapper>
        <PrimaryHeading>{title}</PrimaryHeading>
      </ContentWrapper>
      <ContentWrapper>
        <Grid>
          {articles.map((article) => (
            <ArticlePreview key={article.id} {...article} />
          ))}
        </Grid>
      </ContentWrapper>
    </AppChrome>
  );
}

export default CategoryTemplate;

export const pageQuery = graphql`
  query CategoryBySlug($slug: String!) {
    contentfulCategory(slug: { eq: $slug }) {
      title
      slug
    }
    allContentfulArticle(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "DD MMMM YYYY", locale: "sv")
          category {
            title
            slug
          }
          author {
            name
          }
          image {
            sizes(maxWidth: 700, maxHeight: 392, resizingBehavior: FILL) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          description
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
