import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import { fonts } from '../../lib/constants';

const Container = styled(Link)({});
const Title = styled.div({
  fontFamily: fonts.bold,
  marginBottom: 10,
  fontSize: 24,
});
const Description = styled.div({});
const StyledImg = styled(Img)({
  marginBottom: 20,
});
const PublishDate = styled.div({
  fontSize: 14,
  lineHeight: '15px',
  textTransform: 'uppercase',
  marginBottom: 5,
});

function ArticlePreview({
  description,
  title,
  image,
  publishDate,
  author,
  slug: articleSlug,
  category: { title: categoryTitle, slug: categorySlug },
}) {
  return (
    <Container to={`/${categorySlug}/${articleSlug}`}>
      <StyledImg alt={title} sizes={image.sizes} />
      <PublishDate>
        {publishDate}{author && (<span> - av {author.name}</span>)}
      </PublishDate>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
}

export default ArticlePreview;
